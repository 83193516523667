import React from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/SEO"
import { Container, TextField, Button, Box, Stack } from "@mui/material"

const Contact = () => {
  return (
    <Layout>
      <Seo
        title="Contact"
        desc="Do you have any questions or requests? Please contact us."
      />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
          my: "3rem",
        }}
      >
        <Container maxWidth="sm">
          <form
            autoComplete="off"
            action="https://formspree.io/f/mknpvknw"
            method="POST"
          >
            <Stack spacing={4}>
              <h2 style={{ textAlign: "center" }}>Contact</h2>
              <div>
                <p>Need additional information or have a question?</p>
                <p>
                  Please feel free to leave a message, and our beloved team will
                  answer you as soon as possible.
                </p>
                <p>
                  You can also book private online Vietnamese classes by
                  contacting us.
                </p>
              </div>

              <TextField
                label="Name"
                name="name"
                type="text"
                variant="outlined"
                margin="normal"
                size="small"
                fullWidth
                required
              />
              <TextField
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                margin="normal"
                size="small"
                fullWidth
                required
              />
              <TextField
                label="Message"
                name="message"
                variant="outlined"
                margin="normal"
                size="small"
                multiline
                rows={4}
                fullWidth
                required
              />
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Stack>
          </form>
        </Container>
      </Box>
    </Layout>
  )
}

export default Contact
